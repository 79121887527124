import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static values = { url: String }

  initialize() {
    this.insertMention = this.insertMention.bind(this)
    this.customPaste = this.customPaste.bind(this)
  }

  connect() {
    this.editor = this.element.editor
    this.setup()
    window.editor = this.editor
  }

  disconnect() {
    this.tribute.detach(this.element)
    this.element.removeEventListener("tribute-replaced", this.insertMention)
  }

  setup() {
    this.tribute = new Tribute(this.tributeConfig)
    this.tribute.attach(this.element)
    this.tribute.range.pasteHtml = this.customPaste
    this.element.addEventListener("tribute-replaced", this.insertMention)
  }

  async fetchResults(query, callback) {
    try {
      const response = await fetch(this.url(query))
      const json = await response.json()
      callback(json)
    } catch (error) {
      console.warn(error)
      callback([])
    }
  }

  getResults(query, callback) {
    this.debounce(this.fetchResults.bind(this, query, callback))
  }

  debounce(callback) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(callback, 300);
  }

  url(query) {
    return `${this.urlValue}?query=${encodeURIComponent(query)}`
  }

  insertMention({detail: {item: {original: {sgid, content}}}}) {
    const attachment = new Trix.Attachment({sgid, content})

    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  /**
   * The start & end indexes provided by Tribute are not
   * compatable with Trix, so we need to calculate our own.
   **/
  customPaste(_html, _start, _end) {
    this.insertCursorMark()
    this.editor.setSelectedRange(this.mentionQueryRange)
    this.editor.deleteInDirection("forward")
  }

  /**
   * There is an edge case if the user trys to insert an @mention in the middle
   * of a text run.  We're avoiding that scenario by temporarily breaking the
   * current text-run just to the right of the cursor.
   **/
  insertCursorMark() {
    this.editor.insertHTML("<em data-cursor-mark>&nbsp;</em>")
    this.editor.moveCursorInDirection("backward")
  }

  get tributeConfig() {
    return {
      allowSpaces: true, /** in the query */
      values: this.getResults.bind(this),
      menuShowMinLength: 1, /** this means don't immidiatly display the context menu when an `@` is typed */
      noMatchTemplate: () => '<span class="no-match-prompt">No Matches</span>'
    }
  }

  get mentionQueryRange() {
    const cursorPosition = this.editor.getPosition()
    const currentSegment = this.editor.getDocument().getPieceAtPosition(cursorPosition - 1).string
    const lastAtSignInSegment = currentSegment.lastIndexOf('@')
    const targetRangeStart = cursorPosition - currentSegment.length + lastAtSignInSegment

    /**
     * +1 to the right to delete the <em data-cursor-mark>
     * element which is exactly 1 character wide.
     **/
    return [targetRangeStart, cursorPosition + 1]
  }
}
