import Sortable from "stimulus-sortable"

// Connects to data-controller="sortable-fields"
export default class extends Sortable {
  connect() {
    super.connect()
  }

  async onUpdate({ item, newIndex }) {
    item.querySelector('input[data-position-input]').value = newIndex + 1
  }
}
