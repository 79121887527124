import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    count: Number,
  }

  perform({currentTarget: {name, value, tomselect}, params: {url: urlParam, value: valueParam}}) {
    if (tomselect) {
      value = tomselect.items
    }

    this._perform(name, valueParam ?? value, urlParam)
  }

  async _perform(name, value, url) {
    const fetchURL = new URL(url || this.urlValue)
    const params = new URLSearchParams(fetchURL.search)

    params.set(name, value)

    const response = await get(fetchURL.pathname + '?' + params.toString())
    if (response.ok) this.countValue += 1
  }
}
